import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Header/ShowMobileMenu/ShowMobileMenu.component.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/HomePage/TechStack/techStack.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/HomePage/TechStack/Tech/Tech.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/HomePage/HomePageImageSection/HomePageImageSection.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/HomePage/HeroSectionWrapper/HeroSectionWrapper.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/HomePage/MainSectionWrapper/MainSectionWrapper.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/HomePage/HomePageInfo/HomePageInfoSection.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/HomePage/HomePageInfo/HeroHeader/HeroHeader.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/HomePage/HomePageInfo/HeroCta/HeroCta.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/UI/AnimationWrapper/AnimationWrapper.component.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/UI/Button/Button.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Stats/Stats.page.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Contact/Contact.page.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Contact/ContactInfoSection/ContactInfoSection.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Contact/ContectHeaderSection/ContectHeaderSection.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Footer/Footer.page.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Footer/FooterCopyright/FooterCopyright,component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Footer/FooterCtaIcons/FooterCtaIcons.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Footer/FooterPrivacy/FooterPrivacy.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Footer/FooterWrapper/FooterWrapper.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Opinions/Opinions.page.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Opinions/Opinion/Opinion.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Opinions/OpinionHeader/OpinionHeader.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Opinions/SectionName/SectionName.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Opinions/OpinionsWrapper/OpinionsWrapper.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Projects/ProjectsWrapper/ProjectsWrapper.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Projects/ProjectContainer/ProjectCta/ProjectCta.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Projects/ProjectContainer/ProjectTools/ProjectTools.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Projects/ProjectContainer/ProjectDescription/ProjectDescription.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Projects/ProjectContainer/ProjectTitle/ProjectTitle.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Projects/ProjectContainer/ProjectImage/ProjectImage.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Projects/ProjectContainer/ProjectContainer.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Projects/Projects.page.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Projects/ProjectHeader/ProjectHeader.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/EBook/EbookImageSide/EbookImageSide.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/EBook/EbookCtaWrapper/EbookCtaButton/EbookCtaButton.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/EBook/EbookWrapper/EbookWrapper.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/EBook/EbookCtaWrapper/EbookCtaWrapper.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/EBook/EbookCtaWrapper/EbookCtaHeader/EbookCtaHeader.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/EBook/EbookCtaWrapper/EbookCtaOpinion/EbookCtaOpinion.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/EBook/EbookCtaWrapper/EbookCtaText/EbookCtaText.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/AboutMe/AboutMeWrapper/AboutMeWrapper.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/AboutMe/InfoAboutMe/InfoAboutMe.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/AboutMe/InfoAboutMe/InfoHeader/InfoHeader.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/AboutMe/InfoAboutMe/InfoDescription/InfoDescription.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/AboutMe/StatsWrapperSection/SecondSection.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/AboutMe/StatsWrapperSection/ImageSection/ImageSection.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/AboutMe/StatsWrapperSection/StatsSection/StatsSection.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/AboutMe/StatsWrapperSection/StatsSection/Stat/Stat.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/AboutMe/StatsWrapperSection/StatsSection/KeyTechnologies/KeyTechnologies.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/AboutMe/StatsWrapperSection/StatsSection/Social/Social.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/AboutMe/StatsWrapperSection/StatsSection/IconElement/IconElement.component.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/UI/CounterAnimation/CounterAnimation.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/UI/CursorShadow/CursorShadow.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/UI/TypingRepleaceAnimation/TypingRepleaceAnimation.component.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/UI/Word/Paragraph.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Utils/Text-component/Text.component.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/data/Services.data.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Services/Label/Label.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Services/ServicesHeader/ServicesHeader.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Services/ServicesCta/ServicesCta.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Services/NumberOfService/NumberOfService.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Services/ServicesWrapper/ServicesWrapper.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Header/Logo/HeaderLogo.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Header/NavBar/NavBar.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Header/HeaderWrapper/HeaderWrapper.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Collaboration/CollaborationCta/CollaborationCta.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Collaboration/Step/Step.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Collaboration/Collaboration.page.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Collaboration/CollaborationHeader/CollaborationHeader.component.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
